

import React from 'react'
import Home from '../Home'

function HomePage() {
  return (
    <>
     <Home />
    </>
  )
}

export default HomePage;